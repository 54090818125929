<template>
    <div id="privacy-policy-root" class="footerPageRoot">
        <div class="footer-page-outer-wrapper">
            <div class="footer-page-header">
                <Header />
            </div>

            <div class="footer-page-body outer-wrapper page-body">
                <h1 class="page-title">Privacy Policy</h1>
                <h4 id="title">Last Updated June, 2021</h4>
                <h3>Contents</h3>
                <ol>
                    <a href="#scope"><li>Scope</li></a>
                    <a href="#data"><li>What data do we use?</li></a>
                    <a href="#why"><li>Why do we use this data?</li></a>
                    <a href="#cookies"
                        ><li>Do we use web browser cookies, or pixels?</li></a
                    >
                    <a href="#ever-contact"
                        ><li>
                            Will we ever contact you (and how to opt-out)?
                        </li></a
                    >
                    <a href="#share-data"><li>Will we share this data?</li></a>
                    <a href="#children"
                        ><li>
                            U.S. Children's Online Privacy Protection Act
                            ("COPPA")
                        </li></a
                    >
                    <a href="#cali-consumer"
                        ><li>California Consumer Privacy Act ("CCPA")</li></a
                    >
                    <a href="#8.1"
                        >8.1 Consumers With Disabilities; Non-Discrimination</a
                    ><br />
                    <a href="#8.2"
                        >8.2 Designated Methods For Submitting CCPA Requests</a
                    ><br />
                    <a href="#8.3">8.3 Personal Information</a
                    ><br />
                    <a href="#8.4"
                        >8.4 Necessity To Maintain Your Personal Information</a
                    ><br />
                    <a href="#8.5"
                        >8.5 Opt-out / Opt-in For Sale Of Personal
                        Information</a
                    >
                    <a href="#eu-data"
                        ><li>
                            E.U. General Data Protection Regulation ("GDPR")
                        </li></a
                    >
                    <a href="#9.1">9.1 GDPR Owner</a
                    ><br />
                    <a href="#9.2">9.2 Personal Data</a
                    ><br />
                    <a href="#9.3">9.3 Disclosure</a
                    ><br />
                    <a href="#9.4">9.4 Data Retention</a
                    ><br />
                    <a href="#9.5">9.5 Contact GDPR Owner</a>
                    <a href="#cws"
                        ><li>
                            Privacy Practices Disclosure For Chrome Web Store
                        </li></a
                    >
                    <a href="#policy-updates"><li>Policy Updates</li></a>
                    <a href="#contact-us"><li>Contact Us</li></a>
                </ol>
                <p>
                    This website ("site"), "service", application ("app"), web
                    browser extension ("extension") are owned and operated by
                    Tightrope Interactive, Inc. ("we", "our", "us"), a Delaware
                    (U.S.) corporation.
                </p>

                <h3 id="scope">1. Scope</h3>
                <p>
                    This Privacy Policy ("Privacy Policy") pertains to your use
                    ("user", "you", "your") of our site, service, app,
                    extension, and web push notifications ("Notifications").
                    They may be collectively referred to as "products" except
                    when specified otherwise.
                </p>

                <h3 id="data">2. What data do we use?</h3>
                <p>The data we may use includes:</p>
                <ul>
                    <li>
                        <em>IP Address</em> - Your public "Internet Protocol"
                        address sent to us by your web browser.
                    </li>
                    <li>
                        <em>GUID</em> - A unique "Generic User Identification"
                        created when you visit our site, use our service,
                        install our app, add our extension, or subscribe to
                        Notifications.
                    </li>
                    <li>
                        <em>UA</em> - The "User Agent" and version of your web
                        browser; e.g. Chrome 90, Firefox 89, Safari 14, etc.
                    </li>
                    <li>
                        <em>OS</em> - The "Operating System" and version used by
                        your hardware; e.g. Windows 10, macOS 11, etc.
                    </li>
                    <li>
                        Anonymous service diagnostic data (<em
                            >"diagnostic data"</em
                        >), including but not limited to network connection
                        conditions.
                    </li>
                    <li>
                        <em>Email Address</em> - Your electronic mail address
                        and associated message headers (e.g. Your Name
                        &lt;yourname@example.com&gt; if it is used to create an
                        account with our products.
                    </li>
                </ul>

                <h3 id="why">3. Why do we use this data?</h3>
                <p>
                    We may use this data to enable us to make our products work
                    for you.
                </p>
                <ul>
                    <li>
                        We use your public IP address to determine geography,
                        and prevent fraudulent use of our products.
                    </li>
                    <li>
                        We use a GUID to ensure the proper functionality of our
                        products.
                    </li>
                    <li>
                        We use your UA and OS information to optimize our
                        products for your web browser and operating system.
                    </li>
                    <li>
                        We use anonymous diagnostic data to maintain quality of
                        service.
                    </li>
                    <li>
                        We use your email address and associated message headers
                        to <a href="#ever-contact">contact you</a> about our
                        products.
                    </li>
                </ul>

                <h3 id="cookies">
                    4. Do we use web browser cookies, or pixels?
                </h3>
                <p>
                    Cookies are removable text files stored in your web browser.
                    We may ask your web browser to store one or more cookies
                    when you use our products in order to make them work for
                    you. Pixels or "web beacons" are similar in function to
                    cookies. We may ask your web browser to load pixels for our
                    products to better understand how you use them so we can
                    improve them, and for other business purposes.
                </p>

                <h3 id="ever-contact">
                    5. Will we ever contact you (and how to opt-out)?
                </h3>
                <ul>
                    <li>
                        If you contact us, we may reply using the contact
                        information you provide at that time.
                    </li>
                    <li>
                        You can
                        <a href="/uninstall/" target="_blank"
                            >disable or remove our app or extension at any
                            time</a
                        >.
                    </li>
                    <li>
                        If you opt-in via your web browser to Notifications, we
                        may send them to you via the mechanisms built-in to your
                        User Agent and/or OS.
                    </li>
                    <ul id="disabled-notf" style="display:none">
                        <li>
                            You've disabled this site's Notifications. If you
                            are still seeing notifications, please try our
                            manual
                            <a href="/unsubscribe/" target="_blank"
                                >unsubscribe instructions.</a
                            >
                        </li>
                    </ul>

                    <ul id="not-disabled" style="display:none">
                        <li class="fancy">
                            If you opted-in via your web browser to this site's
                            Notifications, you can unsubscribe by using
                            <span
                                onclick="checkSubscription()"
                                style="cursor: pointer;color:blue;"
                                >this link</span
                            >.
                        </li>
                        You can also disable this site's Notifications via your
                        <a href="/unsubscribe/" target="_blank">
                            web browser settings </a
                        >(a.k.a. options or preferences).
                    </ul>
                    <li>
                        You can
                        <a href="/preferences" target="_blank"
                            >delete your free account if you have one at any
                            time</a
                        >. You should also remove this site's third-party access
                        if you signed up with a
                        <a
                            href="https://support.google.com/accounts/answer/3466521"
                            target="_blank"
                            >Google Account</a
                        >
                        or
                        <a
                            href="https://www.facebook.com/help/211829542181913"
                            target="_blank"
                            >Facebook Account</a
                        >.
                    </li>
                    <li>
                        We may contact you via email about our products. You can
                        unsubscribe via the "Unsubscribe" link in email
                        communication.
                    </li>
                </ul>

                <h3 id="share-data">
                    <span id="section_six">6. Will we share this data?</span>
                </h3>
                <p>
                    Your data may pass through third-party service providers
                    that we use to make our products work for you. These
                    third-parties may include:
                </p>
                <ul>
                    <li>
                        Amazon Web Services
                        <a
                            href="https://aws.amazon.com/compliance/data-privacy-faq/"
                            target="_blank"
                            >(Data Privacy FAQ)</a
                        >
                    </li>
                    <li>
                        Cloudflare
                        <a
                            href="https://www.cloudflare.com/privacypolicy/"
                            target="_blank"
                            >(Privacy Policy)</a
                        >
                    </li>
                    <li>
                        Crazy Egg
                        <a
                            href="https://www.crazyegg.com/privacy"
                            target="_blank"
                            >(Privacy Policy);</a
                        >
                        <a
                            href="https://www.crazyegg.com/opt-out"
                            target="_blank"
                            >Opt Out</a
                        >
                    </li>
                    <li>
                        Facebook
                        <a
                            href="https://www.facebook.com/privacy/"
                            target="_blank"
                            >(Privacy Shortcuts)</a
                        >
                    </li>
                    <li>
                        Google
                        <a
                            href="https://policies.google.com/privacy"
                            target="_blank"
                            >(Privacy Policy)</a
                        >; Google Analytics
                        <a
                            href="https://tools.google.com/dlpage/gaoptout"
                            target="_blank"
                            >Opt-out Browser</a
                        >
                        Add-on
                    </li>
                    <li>
                        IBM Cloud
                        <a href="https://www.ibm.com/privacy/" target="_blank"
                            >(Privacy Statement)</a
                        >
                    </li>
                    <li>
                        Mailgun
                        <a
                            href="https://www.mailgun.com/privacy-policy/"
                            target="_blank"
                            >(Privacy Policy)</a
                        >
                    </li>
                    <li>
                        Stripe
                        <a href="https://stripe.com/privacy" target="_blank"
                            >(Global Privacy Policy - Worldwide)</a
                        >
                    </li>
                    <li>
                        Twilio
                        <a
                            href="https://www.twilio.com/legal/privacy"
                            target="_blank"
                            >(Privacy Statement)</a
                        >
                    </li>
                    <li>
                        Verizon Media
                        <a
                            href="https://www.verizonmedia.com/policies/index.html"
                            target="_blank"
                            >(Privacy Centers)</a
                        >
                    </li>
                </ul>

                <h3 id="children">
                    7. U.S. Children's Online Privacy Protection Act ("COPPA")
                </h3>
                <p>
                    We do not knowingly collect or sell information from
                    children under the age of 13 pursuant to the Children's
                    Online Privacy Protection Act of 1998, 15 U.S.C. 6501-6505.
                    We encourage parents and legal guardians to be familiar with
                    the Internet activity of their children. For more
                    information, visit the United States Federal Trade
                    Commission
                    <a
                        href="https://www.consumer.ftc.gov/topics/protecting-kids-online"
                        target="_blank"
                        >website</a
                    >
                    about protecting kids online.
                </p>

                <h3 id="cali-consumer">
                    8. California Consumer Privacy Act ("CCPA")
                </h3>
                <p>
                    This Section 8 applies to you if you are a natural person
                    who is a California resident, as defined in Section 17014 of
                    Title 18 of the California Code of Regulations. This section
                    describes our good faith effort to meet our obligations if
                    CCPA applies to you.
                </p>

                <h3 id="8.1">
                    8.1 Consumers With Disabilities; Non-Discrimination
                </h3>
                <p>
                    Consumers with disabilities can submit a CCPA request as
                    described below in Section 8.2. We will make a reasonable
                    effort to furnish Section 8 in an alternative format that
                    you can consume. You have a right not to receive
                    discriminatory treatment by us for the exercise of the
                    privacy rights conferred by the CCPA.
                </p>

                <h3 id="8.2">
                    8.2 Designated Methods For Submitting CCPA Requests
                </h3>
                <p>
                    You can contact us to submit a CCPA request via
                    <a
                        id="ccpalink"
                        href="https://privacy.tightropeinteractive.com/?domain=cooking.freshy.com"
                        target="_blank"
                        >this webform,</a
                    >
                    or by calling our automated, toll-free (within the United
                    States) phone number: 1-833-874-2272. Please be aware that
                    your public Caller ID information may be collected for
                    business purposes if you choose the phone call method.
                </p>

                <p class="italic" style="font-style: italic;">
                    For inquiries about our Privacy Policy that are not related
                    to CCPA, please use the
                    <a href="#contact">contact</a> information at the end of
                    this Privacy Policy.
                </p>

                <h3 id="8.3">8.3 Personal Information</h3>
                <p>
                    The State of California Civil Code, Division 3, Part 4,
                    Title 1.81.5. California Consumer Privacy Act of 2018
                    [1798.100 –⁠ 1798.199], CCPA, defines "personal information"
                    as including "information that identifies, relates to,
                    describes, is capable of being associated with, or could
                    reasonably be linked, directly or indirectly, with a
                    particular consumer or household."
                </p>

                <p>
                    For the purposes of our Privacy Policy, the "person" is
                    "you" ("your", "user"). The personal information we collect
                    may include:
                </p>
                <ul>
                    <li>
                        <em>Display Name</em> –⁠ If you enter personal
                        information into the service's "Display Name" text input
                        field, it will be used for business purposes, including
                        using it to temporarily identify you by your chosen
                        display name in a service session.
                    </li>
                    <li>
                        <em>IP Address</em> - Your public "Internet Protocol"
                        address sent to us by your web browser.
                    </li>
                    <li>
                        <em>GUID</em> - A unique "Generic User Identification"
                        created when you visit our site, use our service,
                        install our app, add our extension, or subscribe to
                        Notifications which may be stored in a cookie.
                    </li>
                    <li>
                        <em>Anonymous Diagnostic Data</em> - Anonymous service
                        diagnostic data, including but not limited to network
                        connection conditions.
                    </li>
                    <li>
                        <em>UA</em> - The "User Agent" and version of your web
                        browser; e.g. Chrome 90, Firefox 89, Safari 14, etc.
                    </li>
                    <li>
                        <em>OS</em> - The "Operating System" and version used by
                        your hardware; e.g. Windows 10, macOS 11, etc.
                    </li>
                    <li>
                        <em>Email Address</em> - Your electronic mail address
                        and associated message headers (e.g. Your Name
                        &lt;yourname@example.com&gt;) if it is used to create an
                        account with our products.
                    </li>
                </ul>

                <h3 id="8.4">
                    8.4 Necessity To Maintain Your Personal Information
                </h3>
                <p>
                    The personal information we collect may be used for the
                    following business purposes, which are necessary for us in
                    order to:
                </p>
                <ul>
                    <li>Offer you our products.</li>
                    <li>
                        Detect security incidents, protect against malicious,
                        deceptive, fraudulent, or illegal activity.
                    </li>
                    <li>
                        Debug to identify and repair errors that impair existing
                        intended functionality.
                    </li>
                </ul>

                <h3 id="8.5">
                    8.5 Opt-out / Opt-in For Sale Of Personal Information
                </h3>
                <p>
                    We do not explicitly sell your personal information;
                    therefore, there is no need to opt-out of the sale of your
                    personal information. You may submit a CCPA request as
                    described in Section 8.2 to preemptively opt-out or opt-in
                    your personal information to a sale if one is made within
                    12-months from the date we receive your request.
                </p>

                <h3 id="eu-data">
                    9. E.U. General Data Protection Regulation ("GDPR")
                </h3>
                <p>
                    This Section 9 applies to you if you are a national of a
                    European Economic Area ("EEA") member state. This section
                    describes our good faith effort to meet our obligations if
                    GDPR applies to you.
                </p>

                <h3 id="9.1">9.1 GDPR Owner</h3>
                <p>
                    You can contact our GDPR owner via this
                    <a
                        href="mailto:gdprrequest+freshy.com@tightropeinteractive.com?subject=Privacy%20Policy%20-%20GDPR%20Request&amp;body=(Please%20do%20not%20modify%20the%20email%20subject%20line.)%0A%0AInclude%20the%20domain%20name%20or%20web%20address%20of%20the%20site%20(e.g.%20[bloginfo info='name'])%20you%20are%20inquiring%20about%20here:"
                        >email link,</a
                    >
                    or post:
                </p>

                Tightrope Interactive, Inc.<br />
                ATTN: GDPR Request<br />
                588 Sutter Street #551<br />
                San Francisco, California 94102-1102<br />
                United States of America<br /><br />
                <p class="italic">
                    <em
                        >For inquiries about our Privacy Policy that are not
                        related to GDPR, please use the contact information at
                        the end of this Privacy Policy.</em
                    >
                </p>

                <h3 id="9.2">9.2 Personal Data</h3>
                <p>
                    European Union regulation 2016/679, GDPR, defines "personal
                    data" as "any information relating to an identified or
                    identifiable natural person (â€˜data subject'); an
                    identifiable natural person is one who can be identified,
                    directly or indirectly, in particular by reference to an
                    identifier such as a name, an identification number,
                    location data, an online identifier or to one or more
                    factors specific to the physical, physiological, genetic,
                    mental, economic, cultural or social identity of that
                    natural person"
                </p>

                <p>
                    For the purposes of our Privacy Policy, the "data subject"
                    is "you" ("your", "user"). The personal data we collect may
                    include:
                </p>
                <ul>
                    <li>
                        <em>Display Name</em> –⁠ If you enter personal data into
                        the service's "Display Name" text input field, it will
                        temporarily identify you by your chosen display name in
                        a service session.
                    </li>
                    <li>
                        <em>IP Address</em> - Your public "Internet Protocol"
                        address sent to us by your web browser.
                    </li>
                    <li>
                        <em>GUID</em> - A unique "Generic User Identification"
                        created when you visit our site, use our service,
                        install our app, add our extension, or subscribe to
                        Notifications.
                    </li>
                    <li>
                        <em>Anonymous Diagnostic Data</em> - Anonymous service
                        diagnostic data, including but not limited to network
                        connection conditions.
                    </li>
                    <li>
                        <em>Email Address</em> - Your electronic mail address
                        and associated message headers (e.g. Your Name
                        &lt;yourname@example.com&gt;) if it is used to create an
                        account with our products.
                    </li>
                </ul>

                <p>
                    The personal data we collect may be used for the following
                    purposes:
                </p>
                <ul>
                    <li>Fraud detection to prevent abuse of our products.</li>
                    <li>
                        Geography detection to aid with fraud detection, and the
                        delivery of our products appropriate for your location.
                    </li>
                    <li>
                        <a href="#ever-contact">Electronic communication</a>
                        about our products.
                    </li>
                </ul>

                <p>Our basis for processing personal data includes:</p>
                <ul>
                    <li>
                        GDPR Recital 47: "The processing of personal data
                        strictly necessary for the purposes of preventing fraud
                        also constitutes a legitimate interest of the data
                        controller concerned."
                    </li>
                    <li>
                        GDPR Article 6(1)(f): "processing is necessary for the
                        purposes of the legitimate interests pursued by the
                        controller or by a third party"
                    </li>
                </ul>

                <h3 id="9.3">9.3 Disclosure</h3>
                <p>
                    We may pass your personal data without first obtaining your
                    consent to third parties listed in
                    <a href="#section_six">Section 6</a>.
                </p>

                <h3 id="9.4">9.4 Data Retention</h3>
                <p>
                    We may retain your public IP address in server logs for a
                    time period of at least 20 days. GUID data may be retained
                    for a period of at least 90 days after the last use of our
                    products by a user with a GUID.
                </p>

                <h3 id="9.5">9.5 Contact GDPR Owner</h3>
                <p>
                    Please contact our GDPR owner as described in Section 9.1 to
                    exercise your rights as a GDPR data subject, or to file a
                    complaint. The GDPR owner can help you with GDPR-specific
                    matters including:
                </p>
                <ul>
                    <li>
                        Request a copy of information, if any, stored about you.
                    </li>
                    <li>Correct information, if any, stored about you.</li>
                    <li>Erase information, if any, stored about you.</li>
                    <li>
                        Restrict how information, if any, about you is used.
                    </li>
                    <li>Transfer the information, if any, about you.</li>
                    <li>
                        Object to how your information, if any, is used in
                        certain cases.
                    </li>
                    <li>
                        Request why we are unable to fulfill your request, if
                        applicable.
                    </li>
                </ul>

                <h3 id="cws">
                    10. Privacy Practices Disclosure For Chrome Web Store
                </h3>
                <p>
                    Pursuant to Section 6 of the Google Chrome Web Store
                    "Updated Privacy Policy & Secure Handling Requirements"
                    "Updated on Thursday, November 19, 2020", our extensions for
                    Google Chrome collect your "Location" (e.g. region, IP
                    address), and the "User Activity" (e.g. clicks) of our
                    extensions. We use this information to understand the usage
                    of and improve our products.
                </p>
                <p>
                    Our extensions request the minimum browser permissions and
                    site permissions required to function. You as the user can
                    review the "Site access" and "Permissions" of extensions by
                    opening chrome://extensions in Chrome.
                </p>
                <p>
                    We do not sell this data to third parties, use or transfer
                    this data for purposes that are unrelated to the item's
                    (extension's) core functionality, or use or transfer this
                    data to determine creditworthiness or for lending purposes
                    knowingly by us.
                </p>

                <h3 id="policy-updates">11. Policy Updates</h3>
                <p>
                    We reserve the right to modify this Privacy Policy at any
                    time. We will update this Privacy Policy if we make material
                    changes to it.
                </p>

                <h3 id="contact-us">
                    <span id="contact">12. Contact Us</span>
                </h3>
                <p>
                    If you have questions or concerns about this Privacy Policy,
                    its implementation, or feel that we have not followed it,
                    please let us know by
                    <a href="/contact-us/" target="_blank">email</a> or post:
                </p>

                <p>
                    Tightrope Interactive, Inc.<br />
                    ATTN: Privacy Policy - General Inquiry<br />
                    588 Sutter Street #551<br />
                    San Francisco, California 94102-1102
                </p>
            </div>

            <div class="footer-page-footer">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { documentReady } from "../../utility";

export default {
    components: {
        Header,
        Footer,
    },
    mounted() {
        documentReady.then(() => {
            try {
                if (location.hash.length !== 0) {
                    try {
                        document.querySelector(location.hash).scrollIntoView();
                    } catch {
                        null;
                    }
                } else {
                    document.querySelector("#app_root").scrollTo(0, 0);
                }
            } catch {
                null;
            }
        });
    },
};
</script>

<style scoped src="./footerPage.css"></style>

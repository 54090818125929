<template>
    <div class="recipeCardRoot">
        <div v-if="!hideSave && recipe" class="saveBtnWrapper">
            <recipe-save-btn
                :recipe="recipe"
                :showRemove="showRemoveBtn"
            ></recipe-save-btn>
        </div>
        <a
            :href="
                `/recipe/${recipe.uuid ||
                    (recipe.data && recipe.data.uuid
                        ? recipe.data.uuid
                        : null)}`
            "
            v-if="recipe"
            class="recipe-card-wrapper p-grid p-shadow-1"
        >
            <div
                v-if="thisImage"
                class="recipe-card-left"
                :class="{ 'p-col-12': thisImage, 'p-d-none': !thisImage }"
            >
                <img
                    class="recipe-card-img"
                    :src="thisImage"
                    @error="handleImageError"
                />
            </div>
            <div
                id="RecipeCard"
                class="recipe-card-right p-col-12"
                :class="{ 'p-col-12': thisImage, 'p-col-12': !thisImage }"
            >
                <div class="recipe-card-data">
                    <div
                        v-if="formattedTitle"
                        class="recipe-card-title recipe-card-data"
                    >
                        <div
                            v-if="
                                formattedUrl && formattedUrl != formattedTitle
                            "
                            class="splash-card-tag recipe-card-data"
                        >
                            <span>{{ formattedUrl }}</span>
                        </div>

                        <span v-snip="2" :title="formattedTitle">{{
                            formattedTitle
                        }}</span>
                    </div>
                    <!--
                    <div v-if="formattedCreatedOn" class="recipe-card-created-on-date recipe-card-meta">
                        <span><strong>Added On</strong> <span v-html="formattedCreatedOn"></span></span>
                    </div>
                    -->
                    <div
                        v-if="formattedCalories"
                        class="recipe-card-calories recipe-card-meta"
                    >
                        <span
                            ><strong>Calories</strong>
                            {{ formattedCalories }}</span
                        >
                    </div>
                    <div
                        v-if="formattedCarbs"
                        class="recipe-card-carbs recipe-card-meta"
                    >
                        <span><strong>Carbs</strong> {{ formattedCarbs }}</span>
                    </div>
                    <div
                        v-if="formattedCooktime"
                        class="recipe-card-cooktime recipe-card-meta"
                    >
                        <span
                            ><strong>Cooktime</strong>
                            {{ formattedCooktime }}</span
                        >
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import recipeMixin from "../componentMixins/recipeMixin";
import userMixin from "../../store/mixins/user";
import RecipeSaveBtn from "../recipeSaveBtn/RecipeSaveBtn";
import { getShortMonthName, getDateSuffix } from "../../utility";

export default {
    name: "recipe-card",
    mixins: [recipeMixin, userMixin],
    props: [
        "recipe",
        "image",
        "title",
        "calories",
        "carbs",
        "cooktime",
        "url",
        "hideSave",
        "showRemove",
        "createdOn",
    ],
    components: {
        RecipeSaveBtn,
    },
    data() {
        return {
            srcImage: this.image,
        };
    },
    computed: {
        thisImage() {
            return this.srcImage || null;
        },
        formattedCalories() {
            if (!isNaN(this.calories)) return parseInt(this.calories);
            return this.calories;
        },
        formattedCarbs() {
            if (!isNaN(this.carbs)) return parseInt(this.carbs);
            return this.carbs;
        },
        formattedCooktime() {
            return this.formatTimeFromMinutes(this.cooktime);
        },
        formattedTitle() {
            if (this.title) return this.title;
            if (this.url) return this.url;
            return null;
        },
        formattedUrl() {
            if (this.url && this.title) {
                try {
                    const url = new URL(this.url);
                    return url.host;
                } catch (e) {
                    console.error(e);
                    return this.url;
                }
            } else return null;
        },
        showRemoveBtn() {
            if (this.showRemove) return this.showRemove;
            return null;
        },
        formattedCreatedOn() {
            if (!this.createdOn || isNaN(this.createdOn)) return null;

            const date = new Date(this.createdOn);
            const month = getShortMonthName(date.getMonth());
            const day = date.getDate();
            const year = date.getFullYear();

            if (month && day && year)
                return `<span class="recipe-card-created-date">${month} ${day}<span class="recipe-card-created-date-suffix">${getDateSuffix(
                    day
                )}</span>, ${year}</span>`;
            return null;
        },
    },
    mounted() {},
    methods: {
        onClick() {
            this.$emit("onClick", this.recipe);
        },
        handleImageError() {
            this.srcImage = "/assets/images/placeholder_freshyrecipe.png";
        },
    },
};
</script>

<style scoped src="./recipeCard.css"></style>

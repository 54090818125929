import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import VueSnip from "vue-snip";

import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primevue/resources/themes/nova/theme.css";
import "primeicons/primeicons.css";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import trTracking from "./plugins/trTracking";
import { store } from "./store/store";
import router from "./router";
import { getTypeTag } from "./utility";
import firebase from "./auth/firebase";

let app_main = null;

// This assures us that firebase will be loaded first
firebase.auth().onAuthStateChanged(async () => {
    if (!firebase.auth().currentUser) {
        localStorage.removeItem("access_token");
    }

    if (!app_main) {
        app_main = createApp(App);
        app_main.use(store);
        app_main.use(router);
        app_main.use(PrimeVue);
        app_main.use(VueSnip);
        app_main.use(ToastService);
        app_main.use(ConfirmationService);

        app_main.use(trTracking, {
            defaultTypetag: getTypeTag("Y225_F1_207321"),
        });

        app_main.config.globalProperties.$analytics = firebase.analytics();
        const req = await fetch("/cooking/assets/resources/placeholderImages.json");
        if (req.ok) {
            const res = await req.json();
            if (res && res.urls)
                app_main.config.globalProperties.$placeholderUrls = res.urls;
        }

        app_main.mount("#app");
    }
});
